import { navigate } from "gatsby";
import { uniqBy } from "lodash";
import React from "react";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { InteractiveIcon } from "@source-web/interactive-icon";
import { Span } from "@source-web/span";
import { Table, TableCell, TableRow } from "@source-web/table";

import {
  ContainerWithTitle,
  CustomQuantitySelector
} from "../../../../components";
import { AdviseNotification } from "../../../../components/AdviseNotification";
import { BasketItem } from "../../../../components/Cart/components";
import { PaymentType } from "../../../../components/Cart/components/BasketItem/styles/BasketItem.styles";
import { CartContextType } from "../../../../context/cart/cartContext";
import { CartItem } from "../../../../context/cart/types";
import * as Styled from "../../styles/basket.styles";

interface IBasketTable {
  cart: CartContextType;
  headings: string[];
  formatPrices: (price: number) => string;
  formattedLocale: string;
}

export const BasketTable = ({
  cart,
  headings,
  formatPrices,
  formattedLocale
}: IBasketTable) => {
  const theme = useTheme();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return !isMobile ? (
    <>
      {cart?.cartState.items.length ? (
        <Styled.TableWrapper>
          <Styled.TableContainer>
            <Table headings={headings}>
              {uniqBy(cart.cartState.items, "id")
                .sort((previousProd, nextProd) =>
                  previousProd.name.localeCompare(nextProd.name)
                )
                .map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Styled.NameContainer>
                          <Styled.IconWrapper>
                            <img
                              src={
                                theme.name === "WS10"
                                  ? item.lightIcon.file.url
                                  : item.darkIcon.file.url
                              }
                              alt={item.name}
                            />
                          </Styled.IconWrapper>
                          {
                            <Heading level={5} weight={2}>
                              {item.name}
                            </Heading>
                          }
                        </Styled.NameContainer>
                      </TableCell>
                      <TableCell>
                        <CustomQuantitySelector
                          isLarge
                          value={item.quantity}
                          onInvalid={(evt) => {
                            if (evt === 0) {
                              cart.removeProductFromCart(item.id);
                            }
                          }}
                          onChange={(value) =>
                            cart?.addProductToCart({
                              ...item,
                              quantity: value
                            })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Heading level={5} weight={2}>
                          {formatPrices(
                            parseFloat(
                              item?.priceUpfront ? item?.priceUpfront : ""
                            )
                          )}
                          <br />
                          <PaymentType> (ex VAT)</PaymentType>
                        </Heading>
                      </TableCell>
                      <TableCell>
                        <Heading level={5} weight={2}>
                          {formatPrices(item.price)}
                          <br />
                          <PaymentType> (ex VAT)</PaymentType>
                        </Heading>
                      </TableCell>
                      <TableCell>
                        <Span>Product</Span>
                      </TableCell>
                      <TableCell>
                        <InteractiveIcon
                          srText="remove item from cart"
                          onClick={() => cart.removeProductFromCart(item.id)}
                          group="system"
                          name="delete"
                          size={3}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </Table>
          </Styled.TableContainer>
        </Styled.TableWrapper>
      ) : (
        <Styled.NotificationContainer>
          <AdviseNotification
            title="Your basket is empty"
            state="info"
            text="Let's start by adding something!"
            button={{
              text: "Explore",
              onClick: () => navigate(`/${formattedLocale}/cybershop`)
            }}
          />
        </Styled.NotificationContainer>
      )}
    </>
  ) : cart?.cartState.items.length ? (
    <Styled.CartContainerMobile>
      <ContainerWithTitle title="Products">
        {cart.cartState.items
          .sort((previousProd, nextProd) =>
            previousProd.name.localeCompare(nextProd.name)
          )
          .map((product: CartItem) => {
            return (
              <BasketItem
                isMobile={true}
                addProductToCart={cart.addProductToCart}
                removeProductFromCart={cart.removeProductFromCart}
                product={product}
                key={product.id}
              />
            );
          })}
      </ContainerWithTitle>
    </Styled.CartContainerMobile>
  ) : (
    <Styled.NotificationContainer>
      <AdviseNotification
        title="Your basket is empty"
        state="info"
        text="Let's start by adding something!"
        button={{
          text: "Explore",
          onClick: () => navigate(`/${formattedLocale}/cybershop`)
        }}
      />
    </Styled.NotificationContainer>
  );
};
