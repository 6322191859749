import { graphql, navigate } from "gatsby";
import React, { useState } from "react";
import { useTheme } from "styled-components";

import { Checkbox } from "@source-web/checkbox";
import { Container } from "@source-web/container";
import { Divider } from "@source-web/divider";
import { Heading } from "@source-web/heading";
import { LinkWithIcon } from "@source-web/link-with-icon";
import { SmallButton } from "@source-web/small-button";

import { ThemedSection } from "../../components";
import { PaymentType } from "../../components/Cart/components/BasketItem/styles/BasketItem.styles";
import { PrimaryButton } from "../../components/PrimaryButton";
import { useCart } from "../../context/cart/useCart";
import { priceFormatter } from "../../lib";
import { PageContext } from "../../types";
import { BasketTable, ConfirmationPage } from "./components";
import * as Styled from "./styles/basket.styles";

interface BasketType {
  pageContext: PageContext;
  data: ContentfulCart;
}

export interface ContentfulCart {
  contentfulCart: {
    heading: string;
    secondaryButtonText: string;
    warningText: string;
    termsAndConditionsText: string;
    termsAndConditionsLink: string;
    checkboxErrorIcon: { file: { url: string } };
    errorMessageIcon: { file: { url: string } };
    agreeText: string;
  };
}

export const query = graphql`
  query MyQuery {
    contentfulCart {
      id
      heading
      primaryButtonText
      secondaryButtonText
      warningText
      termsAndConditionsText
      termsAndConditionsLink
      agreeText
      checkboxErrorIcon {
        file {
          url
        }
      }
      errorMessageIcon {
        file {
          url
        }
      }
    }
  }
`;

const Basket = ({ pageContext: { formattedLocale }, data }: BasketType) => {
  const headings = ["Item", "Quantity", "Upfront", "Monthly", "Type"];
  const cart = useCart();
  const theme = useTheme();

  function formatPrices(price: number) {
    return price ? priceFormatter.format(price) : "-,00";
  }

  const [isChecked, setIsChecked] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showErrorIcon, setShowErrorIcon] = useState(false);

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
    setShowErrorMessage(false);
    setShowErrorIcon(false);
  }

  const handleCompletePurchase = () => {
    if (!isChecked) {
      setShowErrorMessage(true);
      setShowErrorIcon(true);
    } else {
      cart.onSubmitCart();
    }
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <ThemedSection appearance="primary">
      {showErrorMessage && (
        <Styled.ErrorMessage>
          <Styled.WarningIcon>
            <img src={data.contentfulCart.errorMessageIcon.file.url} />
          </Styled.WarningIcon>
          <Styled.WarningText>
            {data.contentfulCart.warningText}
          </Styled.WarningText>
        </Styled.ErrorMessage>
      )}

      {cart.isSubmitted ? (
        <Styled.BasketContainer>
          <ConfirmationPage
            isMobile={isMobile}
            createShoppingCart={cart.createShoppingCart}
            formattedLocale={formattedLocale}
          />
        </Styled.BasketContainer>
      ) : (
        <Styled.BasketContainer>
          <>
            {!isMobile ? (
              <Styled.TitleContainer>
                <Heading text={data.contentfulCart.heading} noMargin />
              </Styled.TitleContainer>
            ) : showErrorIcon ? (
              <Styled.TitleContainerMobile>
                <LinkWithIcon
                  size={4}
                  appearance="secondary"
                  text="Back to Cybershop"
                  icon={{
                    name: "arrow-left",
                    justify: "left"
                  }}
                  onClick={() => {
                    navigate(`/${formattedLocale}/cybershop`);
                  }}
                />
              </Styled.TitleContainerMobile>
            ) : (
              <Styled.TitleContainer>
                <Heading level={3}>Basket</Heading>
                <LinkWithIcon
                  size={4}
                  appearance="secondary"
                  text="Back to Cybershop"
                  icon={{
                    name: "arrow-left",
                    justify: "left"
                  }}
                  onClick={() => {
                    navigate(`/${formattedLocale}/cybershop`);
                  }}
                />
              </Styled.TitleContainer>
            )}
            <BasketTable
              {...{
                isMobile,
                cart,
                headings,
                formatPrices,
                formattedLocale
              }}
            />
            {!isChecked && showErrorIcon && !isMobile ? (
              <Styled.TermsOfServiceWarning>
                <Styled.ErrorWrapper>
                  <img src={data.contentfulCart.checkboxErrorIcon.file.url} />
                  <Checkbox
                    value={""}
                    id={""}
                    name={""}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  >
                    <Heading size={1}>
                      {data.contentfulCart.agreeText}
                      <a
                        href={data.contentfulCart.termsAndConditionsLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data.contentfulCart.termsAndConditionsText}
                      </a>
                    </Heading>
                  </Checkbox>
                </Styled.ErrorWrapper>
              </Styled.TermsOfServiceWarning>
            ) : !showErrorIcon ? (
              <Styled.TermsOfServiceMobile>
                {cart?.cartState.items.length > 0 ? (
                  <Styled.CheckedWrapper>
                    <Checkbox
                      value={""}
                      id={""}
                      name={""}
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    >
                      <Heading size={1}>
                        {data.contentfulCart.agreeText}
                        <a
                          href={data.contentfulCart.termsAndConditionsLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {data.contentfulCart.termsAndConditionsText}
                        </a>
                      </Heading>
                    </Checkbox>
                  </Styled.CheckedWrapper>
                ) : null}
              </Styled.TermsOfServiceMobile>
            ) : (
              <Styled.TermsOfServiceMobile>
                <Styled.ErrorWrapper>
                  <img src={data.contentfulCart.checkboxErrorIcon.file.url} />
                  <Checkbox
                    value={""}
                    id={""}
                    name={""}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  >
                    <Heading size={1}>
                      {data.contentfulCart.agreeText}
                      <a
                        href={data.contentfulCart.termsAndConditionsLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data.contentfulCart.termsAndConditionsText}
                      </a>
                    </Heading>
                  </Checkbox>
                </Styled.ErrorWrapper>
              </Styled.TermsOfServiceMobile>
            )}

            {cart?.cartState.items.length && !isMobile ? (
              <>
                <ThemedSection appearance="tertiary">
                  <Styled.PriceWrapper>
                    <Heading level={4} weight={3} text="Total Cost" />

                    <Styled.TotalCostWrapper>
                      <Heading noMargin level={4} weight={3}>
                        {formatPrices(cart.cartState?.priceUpfront || 0)}
                        <br />
                        <PaymentType> (ex VAT)</PaymentType>
                      </Heading>
                      <Heading noMargin level={4} weight={3}>
                        {formatPrices(cart.cartState?.price)}
                        <br />
                        <PaymentType> (ex VAT)</PaymentType>
                      </Heading>
                      <PrimaryButton
                        text="Complete purchase"
                        onClick={handleCompletePurchase}
                        loading={cart.isLoading}
                        state={!cart.cartState?.price ? "disabled" : undefined}
                      />
                    </Styled.TotalCostWrapper>
                  </Styled.PriceWrapper>
                </ThemedSection>
                {!isMobile ? (
                  <Styled.CtasContainer>
                    <PrimaryButton
                      text={data.contentfulCart.secondaryButtonText}
                      appearance="alt1"
                      inverse={theme.name !== "WS10"}
                      onClick={() => navigate(`/${formattedLocale}/cybershop`)}
                    />
                  </Styled.CtasContainer>
                ) : null}
              </>
            ) : null}
          </>
        </Styled.BasketContainer>
      )}

      {!cart.isSubmitted && isMobile ? (
        <Styled.ContainerMobile>
          <Container>
            <Styled.PriceContainerMobile>
              <Styled.HeadingContainerMobile>
                <Heading level={3} weight={3}>
                  Total
                </Heading>
              </Styled.HeadingContainerMobile>
              <div>
                Upfront <br />
                <Heading level={4} weight={3}>
                  {cart.cartState.priceUpfront
                    ? priceFormatter.format(cart.cartState.priceUpfront)
                    : "-,00"}
                </Heading>
              </div>
              <Styled.DividerMobile>
                <Divider
                  orientation="vertical"
                  appearance="secondary"
                ></Divider>
              </Styled.DividerMobile>
              <div>
                Montly <br />
                <Heading level={4} weight={3}>
                  {priceFormatter.format(cart.cartState.price)}
                </Heading>
              </div>
              <Styled.ButtonContainerMobile>
                <SmallButton
                  text="Purchase"
                  appearance="primary"
                  onClick={handleCompletePurchase}
                  loading={cart.isLoading}
                  state={!cart.cartState?.price ? "disabled" : undefined}
                  inverse
                />
                <br />
                <Styled.SpanContainer>
                  <Styled.LabelContainer>
                    All prices exclude VAT
                  </Styled.LabelContainer>
                </Styled.SpanContainer>
              </Styled.ButtonContainerMobile>
            </Styled.PriceContainerMobile>
          </Container>
        </Styled.ContainerMobile>
      ) : null}
    </ThemedSection>
  );
};

export default Basket;
