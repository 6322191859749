import styled, { css } from "styled-components";

export const BasketContainer = styled.div`
  height: max-content;
  height: 10%;
  min-height: calc(140vh - 259px);
  width: 100%;
`;

export const BasketContainerSubmitted = styled.div(
  ({ theme }) => css`
    height: max-content;
    padding: 2em 0;
    height: fit-content;
    min-height: calc(100vh);
    width: 100%;
    background: ${theme.color.monochrome2.default};
  `
);

export const TableWrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.monochrome2.default};
  `
);

export const ErrorMessage = styled.div(
  ({ theme }) => css`
    display: flex;
    background-color: ${theme.color.monochrome1.default};
    max-width: ${theme.gridConfig.springWidth}px;
    margin: auto;
    padding: 1.25em;
    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
      justify-content: left;
      align-items: center;
    }
  `
);

export const WarningIcon = styled.div(
  ({ theme }) => css`
    height: 63px;
    background: #ea1a1a;
    border-radius: 2px 0px 0px 2px;
    width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: ${theme.breakpoints.md}px) {
      width: 39px;
    }
  `
);

export const WarningText = styled.div(
  ({ theme }) => css`
    height: 63px;
    border: 1px solid red;
    border-radius: 0px 2px 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 4px;
    font-size: 14px;
    width: 251px;
    @media (min-width: ${theme.breakpoints.md}px) {
      width: 728px;
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      padding: 16px 26px;
    }
  `
);

export const TermsOfService = styled.div(
  ({ theme }) => css`
    a {
      margin-left: 4px;
      text-underline-offset: 4px;
      font-size: 14px;
    }
    h2 {
      font-size: 14px;
      line-height: 18px;
    }
    a,
    a:hover {
      color: ${theme.name === "WS10"
        ? theme.color.monochrome6.default
        : theme.color.monochrome3.default};
    }
    margin: 12px 0;
    label {
      margin: 0 12px;
    }
    max-width: ${theme.gridConfig.springWidth}px;
    height: 60px;
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 1.25em;
    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
      justify-content: flex-end;
      margin: auto;
      a,
      h2 {
        font-size: 18px;
      }
    }
  `
);

export const TermsOfServiceMobile = styled.div(
  ({ theme }) => css`
    a {
      margin-left: 4px;
      text-underline-offset: 4px;
      font-size: 14px;
    }
    h2 {
      font-size: 14px;
      line-height: 18px;
    }
    a,
    a:hover {
      color: ${theme.name === "WS10"
        ? theme.color.monochrome6.default
        : theme.color.monochrome3.default};
    }
    margin-top: -20% !important;
    label {
      margin: 0 12px;
    }
    max-width: ${theme.gridConfig.springWidth}px;
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 1.25em;
    z-index: 9999 !important;
    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
      justify-content: flex-end;
      margin: auto;
      a,
      h2 {
        font-size: 18px;
      }
    }
  `
);

export const TermsOfServiceWarning = styled(TermsOfService)(
  () => css`
    display: flex;
    justify-content: flex-end;
  `
);

export const CheckedWrapper = styled.div(
  () => css`
    display: flex;
    padding: 12px;
  `
);

export const ErrorWrapper = styled.div(
  () => css`
    border: 1px solid red;
    display: flex;
    padding: 12px;
  `
);

export const CtasContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    gap: 1em;
    max-width: ${theme.gridConfig.springWidth}px;
    margin: 1.25em auto;
    justify-content: flex-end;

    @media (max-width: ${theme.breakpoints.md}px) {
      flex-direction: column;
    }
  `
);

export const PriceWrapper = styled.div(
  ({ theme }) => css`
    max-width: ${theme.gridConfig.springWidth}px;
    margin: auto;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25em;
    & h3,
    span,
    h4 {
      color: ${theme.name === "WS10"
        ? theme.color.monochrome2.default
        : theme.color.monochrome6.default};
    }
  `
);

export const TotalCostWrapper = styled.div(
  () => css`
    display: flex;
    gap: 1.75em;
    justify-content: space-around;
    align-items: center;
  `
);

export const TitleContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.monochrome1.default};
    max-width: ${theme.gridConfig.springWidth}px;
    margin: auto;
    padding: 1.25em;
  `
);

export const TitleContainerMobile = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.monochrome1.default};
    max-width: ${theme.gridConfig.springWidth}px;
    margin-top: -2%;
    padding-left: 1em;
  `
);

export const NotificationContainer = styled.div(
  ({ theme }) => css`
    max-width: ${theme.gridConfig.springWidth}px;
    margin: auto;
  `
);

export const TableContainer = styled.div(
  ({ theme }) => css`
    max-width: ${theme.gridConfig.springWidth}px;
    margin: auto;
    height: max-content;
    & table {
      border-collapse: separate;
      border-spacing: 0 10px;
      margin: 1.25em 0 0 0;
      & td:last-child,
      & th:last-child {
        border-radius: 0 10px 10px 0;
      }
      & tbody > tr {
        background-color: ${theme.color.monochrome2.default};
        align-items: center;
        & > th {
          opacity: 0;
        }
        & > td {
          font-weight: 700;
        }
      }
      @media (max-width: ${theme.breakpoints.md}px) {
        & tr {
          border-top: 2px solid ${theme.color.monochrome6.default};
        }
      }
    }
  `
);

export const TableContainerMobile = styled.div(
  ({ theme }) => css`
    max-width: ${theme.gridConfig.springWidth}px;
    text-align: center !important;
    height: max-content;
    & table {
      border-collapse: separate;
      border-spacing: 0 10px;
      margin: 1.25em 0 0 0;
      & td:last-child,
      & th:last-child {
        border-radius: 0 10px 10px 0;
      }

      & tbody > tr {
        text-align: center !important;
        background-color: ${theme.color.monochrome2.default};
        align-items: center;

        & > th {
          opacity: 0;
        }
        & > td {
          font-weight: 700;
        }
      }

      @media (max-width: ${theme.breakpoints.md}px) {
        & tr {
          border-top: 2px solid ${theme.color.monochrome6.default};
        }
      }
    }
  `
);

export const IconWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    background-color: ${(props) => props.theme.color.monochrome1.default};
    padding-right: 0;
  }
  padding-right: ${(props) => props.theme.spacing.fixed[5]}px;
  border-radius: ${(props) => props.theme.border.radius[2]};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;

  & > img {
    width: 40px;
    height: 40px;
  }
`;

export const NameContainer = styled.div`
  width: 100%;
  gap: 1em;
  display: flex;
  align-items: center;
`;

export const ConfirmationPageWrapper = styled.div(
  ({ theme }) => css`
    gap: 1em;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    padding: 1.25em;
    background: ${theme.color.monochrome2.default};
    height: 100%;
  `
);

export const CartContainer = styled.div`
  & > div {
    padding: 0.75em 0;
  }
  position: absolute;
  overflow-y: scroll;
  max-height: 70vh;
  height: 100%;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20vh;

  @media (min-width${(props) => props.theme.breakpoints.lg}px) {
    padding-right: 30px;
    padding-bottom: 100px;
  }
`;

export const CartContainerMobile = styled.div`
  & > div {
    padding: 0.75em 0;
  }
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  max-height: 70vh;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10vh;

  @media (min-width${(props) => props.theme.breakpoints.lg}px) {
    padding-right: 30px;
    padding-bottom: 100px;
  }
`;

export const PriceContainer = styled.div`
  max-width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  gap: 1.5em;
`;

export const PriceContainerMobile = styled.div`
  max-width: 100% !important;
  text-align: center;
  display: flex;
  gap: 1em;
`;

export const ContainerMobile = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 9;
`;

export const HeadingContainerMobile = styled.div`
  margin-top: -3%;
  margin-left: -2%;
`;

export const ButtonContainer = styled.div`
  gap: 1em;
`;

export const ButtonContainerMobile = styled.div`
  display: flex-column;
  gap: 1em;
`;

export const DividerMobile = styled.div`
  color: #fff;
`;

export const SpanContainer = styled.div`
  padding-top: 0.5em;
  font-size: 10px;
`;

export const LabelContainer = styled.label`
  font-size: 10px;
`;
