import { navigate } from "gatsby";
import React from "react";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { Icon } from "@source-web/icon";
import { Link } from "@source-web/link";
import { Span } from "@source-web/span";

import { PrimaryButton } from "../../../../components/PrimaryButton";
import {
  ConfirmationPageWrapper,
  CtasContainer
} from "../../styles/basket.styles";

export const ConfirmationPage = ({
  formattedLocale,
  createShoppingCart,
  isMobile
}: {
  formattedLocale: string;
  createShoppingCart: () => Promise<void>;
  isMobile?: boolean;
}) => {
  const theme = useTheme();
  const iconForTheme = theme.name === "WS10" ? "hifiLight" : "hifiDark";

  async function handleContinueShopping() {
    navigate(`/${formattedLocale}/cybershop`);
    await createShoppingCart();
  }

  return (
    <ConfirmationPageWrapper>
      <Icon name="tick-outline" group={iconForTheme} size={5} />

      <Heading text="You have successfully placed your order" level={1} />
      <Heading
        text="You will receive an email with a confirmation shortly"
        level={3}
        appearance="secondary"
      />
      {isMobile ? (
        <CtasContainer>
          <PrimaryButton
            width="full"
            text="Continue shopping"
            onClick={() => handleContinueShopping()}
          />
          <Link onClick={() => navigate(`../my-services`)}>
            <Span weight={1}>
              <u>Manage services</u>
            </Span>
          </Link>
        </CtasContainer>
      ) : (
        <CtasContainer>
          <PrimaryButton
            width="auto"
            text="Continue shopping"
            onClick={() => handleContinueShopping()}
          />
        </CtasContainer>
      )}
    </ConfirmationPageWrapper>
  );
};
